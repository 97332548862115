<template>
  <div class="card height-auto v-application--is-ltr text-left">
    <div class="card-body">
      <v-data-table
        :options.sync="options"
        :loading="loading"
        loading-text="Loading... Please wait"
        :headers="headers"
        :items="items"
        item-key="id"
        :show-select="false"
        class="elevation-1 level1 templates"
        text="left"
        hide-default-footer
      >
        <template v-slot:top>
          <v-row class="headerTable-div">
            <v-col md="4" sm="12">
              <p class="title main-color" style="text-align: left">
                {{ page_title }}
              </p>
            </v-col>
            <v-col md="4" sm="12">
              <v-row>
                <v-col md="6" sm="6">
                  <p class="mt-2 text-right">Semester/Quarter</p>
                </v-col>
                <v-col md="6" sm="6">
                  <v-autocomplete
                    :label="$t('Quarters')"
                    :items="quarters"
                    item-text="name"
                    item-value="id"
                    v-model="selectedQuarter"
                    solo
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>

            <v-col md="3" sm="12" class="text-right">
              <!-- ==================================================filters================================================== -->
              <!-- v-if="permissions.add" -->
              <v-icon
                large
                class="main-color"
                @click="openDialog()"
                :title="$t('Add New')"
                >add_circle</v-icon
              >
            </v-col>
          </v-row>
        </template>
      </v-data-table>
      <!-- ======================================== Pagination ======================================  -->
      <div class="text-center pt-2">
        <v-pagination
          v-if="length > 0 && totalItems > 0"
          v-model="page"
          :length="length"
          :circle="circle"
          :page="page"
          :total-visible="totalVisible"
          next-icon="mdi-menu-right"
          prev-icon="mdi-menu-left"
        ></v-pagination>
        <v-snackbar
          v-model="snack"
          :timeout="snackTime"
          :color="snackColor"
          :right="true"
          :top="true"
        >
          <!-- {{ snackText }} -->

          <span class="white--text">{{ snackText }}</span>

          <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
        </v-snackbar>
      </div>
    </div>
    <v-dialog v-model="AddEditdialog" persistent max-width="500px" scrollable>
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t(formTitle) }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" class="mt-5">
              <v-row>
                <v-col cols="12" sm="12" md="12" style="text-align: center">
                  <div v-if="errMessage">
                    <p class="red--text">{{ errMessage }}</p>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <label>{{ $t("Exam Name") }}</label>
                  <v-text-field
                    v-model="editedItem.name"
                    :rules="[
                      validationRules.required,
                      validationRules.maxLength50,
                    ]"
                    solo
                    dense
                  ></v-text-field>
                  <!-- :disabled="editedItem.confirmed" -->
                  <div v-if="validation_errors.name">
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.name"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <label>{{ $t("Qualifier") }} </label>
                  <v-autocomplete
                    :items="qualifiers"
                    item-text="qualifier_title"
                    item-value="subject_qualifier_quarter_id"
                    v-model.trim="editedItem.subject_qualifier_quarter_id"
                    :rules="[validationRules.required]"
                    @change="getMarkActivites"
                    solo
                    dense
                  >
                  </v-autocomplete>

                  <div v-if="validation_errors.subject_qualifier_quarter_id">
                    <p
                      class="red--text"
                      v-for="(
                        error, index
                      ) in validation_errors.subject_qualifier_quarter_id"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>
                <!-- activities -->
                <v-col cols="12" md="6">
                  <label>{{ $t("Activity") }} (optional)</label>
                  <v-autocomplete
                    :items="activities"
                    v-model="editedItem.activity_id"
                    item-text="name"
                    item-value="id"
                    dense
                    solo
                    @change="setDefualtMark()"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="12" md="6">
                  <label>{{ $t("Mark") }} </label>
                  <p
                    style="width: 30%; background: #eee; padding: 10px"
                    v-if="this.editedItem.activity_id"
                  >
                    {{ editedItem.mark }}
                  </p>
                  <v-text-field
                    v-model="editedItem.mark"
                    v-if="this.editedItem.activity_id == ''"
                    :rules="[
                      validationRules.required,
                      // validationRules.numeric,
                      validationRules.floatNumberNotZero,
                    ]"
                    @keyup="CheckIfMarksExceedQualifireMark()"
                    solo
                    dense
                  ></v-text-field>
                  <p
                    v-if="showMarkError && this.editedItem.activity_id == ''"
                    class="red--text"
                  >
                    {{ $t("Cannot exceed this Qualifier Total Mark, ") }}
                    {{ max_mark }}
                  </p>

                  <div
                    v-if="
                      validation_errors.mark &&
                      this.editedItem.activity_id == ''
                    "
                  >
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.mark"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" md="12"></v-col>
                <v-col cols="12" sm="6" md="6">
                  <label>{{ $t("Date") }}</label>
                  <v-text-field
                    id="exam_date"
                    autocomplete="off"
                    :placeholder="$i18n.t('Enter date')"
                    class="dateIcon"
                    solo
                    dense
                    v-model="editedItem.exam_date"
                    :rules="[validationRules.required]"
                    append-icon="event"
                    @keydown.prevent
                    @paste.prevent
                    @drop.prevent
                    @focus="onFocus()"
                    @change="checkOnlineExam()"
                  ></v-text-field>
                  <div v-if="validation_errors.exam_date">
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.exam_date"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>

                <!-- :rules="[
                      validationRules.required,
                      startDateTimeOrEqualRule(
                        itemDate.from,
                        itemDate.to,
                        $t(
                          'Start date should be less than or equal to end date'
                        )
                      ),
                    ]" -->

                <v-col cols="12" sm="6" md="6">
                  <label>{{ $t("Time") }}</label>
                  <v-menu
                    ref="menu"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="editedItem.time"
                    transition="scale-transition"
                    offset-y
                    max-width="300px"
                    min-width="300px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedItem.time"
                        :placeholder="$i18n.t('Enter time')"
                        append-icon="access_time"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @keydown.prevent
                        @paste.prevent
                        @drop.prevent
                        @focus="onFocus()"
                        :rules="[validationRules.required]"
                        solo
                        dense
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu2"
                      class="time"
                      v-model="editedItem.time"
                      @click:minute="$refs.menu.save(editedItem.time)"
                    ></v-time-picker>
                  </v-menu>

                  <div v-if="validation_errors.time">
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.time"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-row>
                    <v-col
                      cols="6"
                      sm="6"
                      md="6"
                      style="padding-right: 0 !important; position: relative"
                    >
                      <label>{{ $t("Duration") }}</label>
                      <v-text-field
                        v-model="editedItem.duration_hr"
                        solo
                        dense
                        min="0"
                        type="number"
                        @focus="onFocus()"
                        @blur="onBlurHr(editedItem.duration_hr)"
                        autocomplete="off"
                        @keyup="
                          validateDuration(
                            editedItem.duration_hr,
                            editedItem.duration_min
                          )
                        "
                        :placeholder="$i18n.t('hr')"
                      ></v-text-field>

                      <div v-if="validation_errors.duration_hr">
                        <p
                          class="red--text"
                          v-for="(
                            error, index
                          ) in validation_errors.duration_hr"
                          :key="index"
                        >
                          {{ error }}
                        </p>
                      </div>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      style="padding-right: 0 !important; position: relative"
                    >
                      <label style="visibility: hidden">{{
                        $t("Duration")
                      }}</label>
                      <v-text-field
                        v-model="editedItem.duration_min"
                        autocomplete="off"
                        solo
                        dense
                        type="number"
                        max="60"
                        min="0"
                        @focus="onFocus()"
                        @blur="onBlurMin(editedItem.duration_min)"
                        @keyup="
                          validateDuration(
                            editedItem.duration_hr,
                            editedItem.duration_min
                          )
                        "
                        :placeholder="$i18n.t('min')"
                      ></v-text-field>
                      <span class="betweencolon">:</span>
                    </v-col>

                    <v-col cols="12" sm="12">
                      <p
                        class="red--text dur-error"
                        style="font-size: 0.865rem"
                      >
                        {{ validationMsg }}
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-checkbox
                    v-model="editedItem.online_exam"
                    :disabled="pastDate || pasedQuarter || pastTime"
                    @change="hideCheckboxs()"
                    :label="$i18n.t('Create online exam')"
                  ></v-checkbox>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <v-checkbox
                    v-model="editedItem.is_arabic"
                    v-if="editedItem.online_exam"
                    :label="$i18n.t('Arabic Exam')"
                    :disabled="editedIndex > -1"
                  ></v-checkbox>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <v-checkbox
                    v-model="editedItem.auto_correction"
                    v-if="showCheckboxs"
                    :label="$t('Autocorrection')"
                    :disabled="editedIndex > -1"
                  ></v-checkbox>
                  <div
                    v-if="editedItem.auto_correction && editedItem.online_exam"
                  >
                    <p class="red--text">
                      {{
                        $t("Essay questions won't be available for this option")
                      }}
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-checkbox
                    v-if="showCheckboxs"
                    v-model="editedItem.randomiz"
                    :label="$t('Randomize Questions Order')"
                    :disabled="editedIndex > -1"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn class="modal-btn-cancel cancelBtn" @click="close">{{
            $t("Close")
          }}</v-btn>
          <v-btn
            class="modal-btn-save saveBtn"
            @click="save"
            :loading="loading"
            :disabled="loading"
            >{{ $t("Save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
// import addEditExamComponent from "../../components/modals/addEditExamComponent";
import { validationMixin } from "../../mixins/validationMixin";

export default {
  name: "Exams",
  // components: { addEditExamComponent },
  mixins: [validationMixin],
  data() {
    return {
      page_title: "Exams",
      selectedQuarter: "",
      quarters: [],
      loading: false,
      options: {
        itemsPerPage: 15,
      },
      // form: "",
      snack: "",
      snackTime: "300",
      snackText: "",
      snackColor: "success",
      headers: [
        {
          text: this.$i18n.t("Exam Name"),
          value: "name",
          align: "center",
          sortable: true,
        },
        {
          text: this.$i18n.t("Qualifier"),
          value: "qualifier_title",
          sortable: true,
          align: "center",
        },
        {
          text: this.$i18n.t("Activity"),
          value: "activity",
          sortable: true,
          align: "center",
        },
        {
          text: this.$i18n.t("Mark"),
          value: "mark",
          sortable: true,
          align: "center",
        },
        {
          text: this.$i18n.t("Date"),
          value: "exam_date",
          sortable: true,
          align: "center",
        },
        {
          text: this.$i18n.t("Time"),
          value: "time",
          sortable: true,
          align: "center",
        },
        {
          text: this.$i18n.t("Duration"),
          value: "duration",
          sortable: true,
          align: "center",
        },

        { text: this.$i18n.t("Actions"), value: "actions", sortable: false },
      ],
      items: [],
      qualifiers: [],
      search: "",
      circle: true,
      page: 1,
      length: "",
      totalVisible: "",
      totalItems: "",
      editedItem: {},
      subject_id: "",
      academic_grade_id: "",
      editedIndex: -1,
      formTitle: "",
      AddEditdialog: false,
      menu2: false,
      valid: true,
      validationMsg: "",
      durationValid: false,
      validation_errors: {
        name: "",
        subject_id: "",
        qualifier_id: "",
        mark: "",
        exam_date: "",
        academic_year_id: "",
        duration_hr: "",
      },
      // loading: false,
      marError: false,
      errMessage: "",
      // qualifiers: [],
      academicPeriods: [],
      subjects: [],
      activities: [],
      qualifierMark: "",
      showCheckboxs: false,
      max_mark: "",
      showMarkError: false,
      pastDate: false,
      pasedQuarter: false,
      pastTime: false,
      currntQuarterid: "",
      // quarter_end_date: "",
      // quarter_start_date: "",
    };
  },
  watch: {
    selectedQuarter: {
      handler() {
        this.getAllData();
        this.getQualifires();
        this.quarters.forEach((el) => {
          // console.log("here");
          if (el.id == this.selectedQuarter) {
            // console.log(el.quarter_end_date);
            const today = new Date();
            const yyyy = today.getFullYear();
            let mm = today.getMonth() + 1; // Months start at 0!
            let dd = today.getDate();
            if (dd < 10) dd = "0" + dd;
            if (mm < 10) mm = "0" + mm;
            const formattedToday = dd + "/" + mm + "/" + yyyy;
            if (formattedToday > el.quarter_end_date) {
              this.editedItem.online_exam = 0;
              this.pastDate = true;
              this.pasedQuarter = true;
              this.showCheckboxs = false;
            } else {
              this.pastDate = false;
              this.pasedQuarter = false;
            }
          }
        });
      },
      deep: true,
    },
    page: function () {
      this.getAllData();
    },
    "editedItem.exam_date": {
      handler() {
        if (this.editedItem.exam_date != "") {
          const today = new Date();
          const tyyyy = today.getFullYear();
          let tmm = today.getMonth() + 1; // Months start at 0!
          let tdd = today.getDate();

          if (tdd < 10) tdd = "0" + tdd;
          if (tmm < 10) tmm = "0" + tmm;

          const formattedToday = tdd + "/" + tmm + "/" + tyyyy;
          // console.log(formattedToday);
          if (formattedToday > this.editedItem.exam_date) {
            this.editedItem.online_exam = 0;
            this.pastDate = true;
            this.showCheckboxs = false;
          } else {
            this.pastDate = false;
          }
        }
      },
      deep: true,
    },
    "editedItem.time": {
      handler() {
        const today = new Date();
        const tyyyy = today.getFullYear();
        let tmm = today.getMonth() + 1; // Months start at 0!
        let tdd = today.getDate();
        if (tdd < 10) tdd = "0" + tdd;
        if (tmm < 10) tmm = "0" + tmm;
        const formattedToday = tdd + "/" + tmm + "/" + tyyyy;
        if (formattedToday == this.editedItem.exam_date) {
          let hh = today.getHours();
          let mm = today.getMinutes();

          if (hh < 10) hh = "0" + hh;
          if (mm < 10) mm = "0" + mm;

          var time = hh + ":" + mm;

          if (time > this.editedItem.time) {
            this.editedItem.online_exam = 0;
            this.pastTime = true;
            this.showCheckboxs = false;
          } else {
            this.pastTime = false;
          }
        } else {
          this.pastTime = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    openDialog() {
      var _this = this;
      this.editedItem = {
        // name: "",
        activity_id: "",
        quarter_id: this.selectedQuarter,
        subject_id: this.subject_id,
        academic_grade_year_id: this.academic_grade_id,
        // online_exam: 0,
        exam_date: "",

        // subject_qualifier_quarter_id: "",
      };

      this.AddEditdialog = true;
      this.formTitle = this.$i18n.t("Add Exam");
      $(document).ready(function () {
        $("#exam_date").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function (date) {
            if (date[0]) {
              var dd = date[0]._day;
              var mm = date[0]._month;
              if (dd < 10) dd = "0" + dd;
              if (mm < 10) mm = "0" + mm;
              var edate = dd + "/" + mm + "/" + date[0]._year;
              _this.editedItem.exam_date = edate;

              console.log();
            } else {
              _this.editedItem.exam_date = "";
            }
          },
        });
      });
    },
    getQuarters() {
      axios
        .get(this.getApiUrl + "/exams/filter/getQuartersForFilter", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.quarters = response.data.data.quarters;

          this.currntQuarterid = response.data.data.currntQuarterid;
          this.selectedQuarter = response.data.data.currntQuarterid;
        });
    },
    getQualifires() {
      if (this.selectedQuarter) {
        axios
          .get(
            this.getApiUrl +
              "/performance/exams/getSubjectQuarterQualifiers/" +
              this.selectedQuarter +
              "/" +
              this.subject_id,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            this.qualifiers = response.data.data;
          });
      }
    },
    getAllData() {
      if (this.selectedQuarter) {
        this.items = [];
        axios
          .get(
            this.getApiUrl +
              "/performance/get-exams/" +
              this.academic_grade_id +
              "/" +
              this.selectedQuarter +
              "/" +
              this.subject_id +
              "?page=" +
              this.page,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                //the token is a variable which holds the token
              },
            }
          )
          .then((response) => {
            this.setPaginationParameters(response);
            this.totalItems = response.data.data.exams.total;
            this.items = response.data.data.exams.data;
            this.page_title = response.data.data.page_title;
          });
      }
    },
    setPaginationParameters(response) {
      this.length = response.data.data.exams.last_page;
      if (this.length > 5) this.totalVisible = 5;
    },

    close() {
      this.AddEditdialog = false;
      this.max_mark = "";
      this.activities = [];
      this.$refs.form.reset();
      this.showMarkError = false;
      // this.editedItem.online_exam = 0;
      this.editedItem = {};
      this.pastDate = false;
      this.validation_errors = {
        name: "",

        subject_qualifier_quarter_id: "",

        exam_date: "",
      };
      this.validation_errors = {
        name: "",

        subject_qualifier_quarter_id: "",

        exam_date: "",
      };
    },

    save() {
      if (this.editedItem.duration_min == "" || this.editedItem.duration_min == 0 || !this.editedItem.duration_min) {
        this.editedItem.duration_min = "00";
      }
      if (this.editedItem.duration_hr == "" || this.editedItem.duration_hr == 0 || !this.editedItem.duration_hr) {
        this.editedItem.duration_hr = "00";
      }
      // if (this.editedItem.duration_hr == "00" && this.editedItem.duration_min == "00"){
      //   this.valid = false;
      //   this.durationValid = false;
      // }
        this.validateDuration(
          this.editedItem.duration_hr,
          this.editedItem.duration_min
        );
      // mahmoud kamal edit
      // if (
      //   Number(this.editedItem.mark) > Number(this.qualifierMark) &&
      //   this.editedIndex == -1
      // ) {
      //   this.marError = true;
      // } else

      console.log(111, this.valid == false, this.durationValid == false)
      if (this.valid == false || this.durationValid == false) {
        this.validate();
      } else {
        this.loading = true;

        axios
          .post(this.getApiUrl + "/performance/exams/store", this.editedItem, {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          })
          .then((response) => {
            this.resetValidation();
            if (response.data.status.error == true) {
              Object.assign(
                this.validation_errors,
                response.data.status.validation_errors
              );
              if (response.data.status.validation_errors.length == 0) {
                this.errMessage = response.data.status.message;
              }
            } else {
              this.disabledSubmitbtn = true;

              let data = response.data.data;
              // let classId = response.data.data.class_id;
              let examId = response.data.data.id;
              // this.$emit("childToParent", false, null, null);
              if (data.online_exam == true || data.online_exam == "true") {
                this.$router.push(
                  {
                    path:
                      "/" + "createOnlineExam/" + examId + "?tab=" + this.tab,
                  },
                  () => {}
                );
              }
              this.qualifiers = [];
              this.getAllData();
              this.close();
            }

            this.loading = false;
          });
      }
    },
    getMarkActivites() {
      if (this.editedItem.subject_qualifier_quarter_id) {
        this.editedItem.mark = "";
        this.editedItem.activity_id = "";
        axios
          .get(
            this.getApiUrl +
              "/performance/get-qualifier-activities/" +
              this.editedItem.subject_qualifier_quarter_id,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                //the token is a variable which holds the token
              },
            }
          )
          .then((response) => {
            // console.log(response.data.data.activities);
            this.max_mark = response.data.data.qualifier_total_mark;
            this.activities = response.data.data.activities;
          });
      }
    },
    CheckIfMarksExceedQualifireMark() {
      if (this.max_mark) {
        let mark = parseFloat(this.editedItem.mark);
        let max_mark = parseFloat(this.max_mark);

        // console.log("mark " + mark + " max " + max_mark);
        if (mark > max_mark) {
          this.showMarkError = true;
        } else {
          this.showMarkError = false;
        }
      }
    },
    setDefualtMark() {
      // console.log("ss", this.editedItem.activity_id);
      if (this.editedItem.activity_id) {
        this.activities.forEach((el) => {
          if (el.id == this.editedItem.activity_id) {
            this.editedItem.mark = el.mark;
          }
        });
      } else {
        this.editedItem.mark = "";
      }
    },
    hideCheckboxs() {
      if (this.editedItem.online_exam) {
        this.showCheckboxs = true;
      } else {
        this.showCheckboxs = false;
      }
    },
    validateDuration(hr, min) {
      if ((!hr && !min) || (hr == "" && min == "") ) {
        this.validationMsg = this.$i18n.t("The duration fields is required");
        this.durationValid = false;
      } else if (hr && isNaN(hr)) {
        this.validationMsg = this.$i18n.t("Hr must be Numeric");
        this.durationValid = false;
      } else if (min && isNaN(min)) {
        this.validationMsg = this.$i18n.t("Min must be Numeric");
        this.durationValid = false;
      } else if ((hr == 0 && (min == 0 || min == "00") )||( hr == "00" && min == "00")) {
        this.validationMsg = this.$i18n.t("Duration must be more than 0");
        this.durationValid = false;
      } else if (min >= 60) {
        this.validationMsg = "Min must be less than 60";
        this.editedItem.duration_min = "";
        this.durationValid = false;
      } else {
        this.validationMsg = "";
        this.durationValid = true;
      }
      console.log(this.validationMsg, this.durationValid)
    },
    onBlurMin(min) {
      if (min == "" || !min) {
        this.editedItem.duration_min = "00";
      }
      if (!isNaN(min) && min < 10 && !min.startsWith("0")) {
        this.editedItem.duration_min = "0" + min;
      }
    },
    onBlurHr(hr) {
      if (hr == "" || !hr) {
        this.editedItem.duration_hr = "00";
      }
      if (!isNaN(hr) && hr < 10 && !hr.startsWith("0")) {
        this.editedItem.duration_hr = "0" + hr;
      }
    },
    onFocus() {
      this.validation_errors = {
        name: "",
        subject_id: "",
        qualifier_id: "",
        mark: "",
        exam_date: "",
        academic_year_id: "",
        randomiz: "",
        duration_hr: "",
      };
    },
    // checkCurentquarter(e) {
    //   console.log(e);
    // },
  },
  mounted() {
    this.subject_id = this.$router.currentRoute.params.subject_id;
    this.academic_grade_id = this.$router.currentRoute.params.grade_id;
    this.getQuarters();
    setTimeout(() => {
      this.getQualifires();
    }, 500);
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

// end search input
.title-page {
  margin-top: 15px;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.card-container {
  max-width: 80%;
  border-radius: 10px;
  position: relative;
  margin-bottom: 50px;
}
.card-container .actions {
  position: absolute;
  top: -39px;
  right: 22px;
}
.card-container .actions i,
.card-container .actions span {
  cursor: pointer;
}
.card-container .actions span {
  margin-left: 5px;
}

.title-qualifire .divider {
  margin-top: 10px;
  margin-bottom: 10px;
}
.q-box-title {
  font-size: 29px;
  position: relative;
}

.q-box-title h2 {
  font-size: 25px;
  padding: 0;
  margin: 0;
}

.title-page {
  margin-top: 15px;
}
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #eef3ff;
  margin-bottom: 33px;
}
.v-expansion-panel-content {
  background-color: #f7f7f7;
}
.title-panel {
  color: rgb(114, 151, 255);
  margin: 0;
  margin-top: 15px;
  padding: 0;
}
.details {
  margin-top: 6%;
  overflow: hidden;
}
.details span {
  color: #8d8a8a;
  font-size: 17px;
  font-weight: 700;
}
.details span.passing {
  float: right;
}
.v-expansion-panel-content__wrap {
  padding: 0;
}
.space-name {
  margin-top: -15px !important;
}

.betweencolon {
  position: absolute;
  top: 3rem;
  left: 5%;
}

.dur-error {
  margin-top: 0 !important;
}
.maxSize {
  font-size: 10px;
}
</style>
